:root {
  --white: #eceff4;
  --light-grey: #d8dee9;
  --grey: #4c566a;
  --black: #2e3440;

  --text-color: var(--grey);

  --border: 1px solid var(--light-grey);
}

.App {
  padding: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
